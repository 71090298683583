
.wrapper
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  width: 100%
  padding: .5rem 0
  margin: 0 0 2rem
  .body
    width: 80%
  .card
    background: #222 !important
    border: none !important
  .btn, .btnActive
    border-radius: 0.5rem
    text-decoration: none
    border: none !important
    box-shadow: none !important
    outline: none !important
    color: white !important
    font-size: 1.8rem
    padding: .5rem 0
    height: 4rem
    width: 100%
    background: #222 !important
    display: flex
    align-items: center
    justify-content: flex-start
    font-weight: 500
  .btn
    &:hover
      background: #f48221 !important
    .bar
      width: 100%
      display: flex
      padding: 0 1.5rem
      justify-content: flex-start
      align-items: center
      font-size: 1.6rem
      color: inherit
      .icon
        margin: 0
        width: 2.4rem
        display: flex
        align-items: center
        justify-content: center
        height: 2.4rem
      span
        margin: 0 1rem
    .arrow, .arrowOpen
      margin: 0 -3rem
      border-style: solid
      border-color: white
      border-width: 0 2px 2px 0
      transform: rotate(45deg)
      display: block
      height: .7rem
      width: .7rem
      transition: all 1s
    .arrowOpen
      transform: rotate(-45deg)

  .btnActive
    background: #f48221 !important
    color: white !important
    font-weight: 900

.NavigationItem
  margin: 0 0 2rem
  display: flex
  padding: 0 .5rem

.NavigationItem:last-child
  margin: 0

.NavigationItem a
  color: white
  opacity: .48
  text-decoration: none
  display: flex
  font-size: 1.4rem
  cursor: pointer

.NavigationItem a:hover
  text-decoration: none
  font-weight: 500
  color: white
  opacity: 1

.NavigationItem a:active,
.NavigationItem a.active
  text-decoration: none
  font-weight: 500
  color: white
  opacity: 1

.hideNavItem
  display: none !important

.showNavItem
  display: inline !important
