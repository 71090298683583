.wrapper
    margin-top: 41px
    margin-bottom: 55px
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    width: 100%
    .typeInput
        display: flex
        flex-direction: column 
        margin-left: 1rem
        margin-right: 1rem
        .inputLabel
            margin-bottom: 10px
            font-size: 16px
            font-weight: bold
            color: #2d2d2d
        .menuWrapper
            max-height: 30rem
            overflow: auto
            min-width: 100% !important
            margin: 5px 0
            border-radius: 5px
            box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.15)
            padding: 0
            button
                display: flex
                align-items: center
                width: 100%
                box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.15)
                border: 0 none
                padding: 0 !important
                outline: none
                background-color: #ffffff
            .item
                padding: 16px
                font-size: 18px
                outline: 0 !important
                text-transform: capitalize
        .switch
            font-size: 1.6rem
            margin: 0 
            height: 56px
            width: 150px
            border-radius: 5px
            box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
            border: solid 1px #a6a6a6
            outline: none 
            background-color: white 
            text-align: left 
            padding: 0
            font-weight: 500
            color: #222222 
            box-shadow: none 
            &:hover, &:visited, &:focus
                background: white
                color: #222222 
            div
                display: flex !important
                align-items: center !important
                justify-content: space-between !important
                .triangleIcon
                    width: 0
                    height: 0
                    border-left: 7px solid transparent
                    border-right: 7px solid transparent
                    border-top: 7px solid black
                    margin-right: 1rem
    .inputWrapper
        display: flex
        flex-direction: column
        justify-content: center
        align-items: flex-start
        height: 100%
        width: 100%
        .inputLabel
            margin-bottom: 10px
            font-size: 16px
            font-weight: bold
            color: #2d2d2d
        .inputLabelRow
            display: flex
            justify-content: space-between
            width: 100%
            align-items: center
            .inputLabel
                margin-bottom: 10px
                font-size: 16px
                font-weight: bold
                color: #2d2d2d
    .dropDownSearchInput
        width: 200px
        height: 56px
        border-radius: 5px
        box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
        border: solid 1px #a6a6a6
        background-color: #ffffff
        .customSelectToggleWrapper
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            height: 100%
            width: 100%
            padding: 16px 16px
            border: none
            .triangleIcon
                width: 0
                height: 0
                border-left: 7px solid transparent
                border-right: 7px solid transparent
                border-top: 7px solid black

    .withCurrencyRow
        display: flex
        justify-content: space-between
        align-items: center
    .pointsInput, .pointsInputDimmed
        width: 349px
        height: 55px
        padding: 17px 12px 16px 16px
        border-radius: 5px
        box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
        border: solid 1px #a6a6a6
        background-color: #ffffff
        font-size: 16px
        .input 
            border: 0px
            outline: 0px 
            width: 80%
            height: 100%
        &:focus
            outline: none
    .pointsInputDimmed
        opacity: 0.5 
    .pointsInput::-webkit-input-placeholder
        font-size: 16px
        color: #a6a6a6
