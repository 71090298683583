.wrapper
    width: 722px
    height: 56px
    border-radius: 4px
    border: solid 1px #a6a6a6
    background-color: #ffffff
    outline: none
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    font-size: 18px
    font-weight: bold
    line-height: 1
    letter-spacing: 0.13px
    text-align: center
    color: #a6a6a6
    &:focus-within
        outline: none
