
.wrapper
  outline: none !important
  width: 56.8rem
  margin: auto
  margin-top: 15rem
.insideWrapper
  width: 56.8rem
  border-radius: 5px
  box-shadow: 34px 55px 69px 34px rgba(31, 4, 48, 0.1)
  background-color: #ffffff
  display: flex
  flex-direction: column
  // margin: 0 46rem
  // margin-top: 39rem
  outline: none !important
  .contentWrapper
    .content
      display: flex
      flex-direction: row
      padding: 1.4rem 2.8rem
      border-bottom: 1px solid #e6e6e6
      .phoneCall
        width: 4.8rem
        height: 4.8rem
        border-radius: 50%
        margin-top: 1.1rem
        background-color: rgba(65, 18, 108, 0.35)
        display: flex
        justify-content: center
        align-self: center
        img
          width: 2.4rem
          height: 2.4rem
          margin: auto

      .infoWrapper
        display: flex
        width: 100%
        flex-direction: column
        padding: 1rem

        .helpRequestWrapper
          display: flex
          flex-direction: row
          justify-content: space-between
          .helpRequestTitle
            font-size: 1.2rem
            font-weight: 900
            color: #41126c
            text-transform: uppercase
          .close
            cursor: pointer
        .brandWrapper
          margin: .8rem 0 0 0
          display: flex
          flex-direction: column
          .title
            font-size: 1.4rem
            font-weight: 900
            color: #1a1a1a
          .secondTitle
            font-size: 1.4rem
            font-weight: 500
            color: #1a1a1a
        .phoneNumber
          font-size: 1.4rem
          font-weight: 900
          color: #41126c
.contentWrapper
  height: 40rem
  overflow: scroll
.header
  font-size: 2.2rem
  font-weight: 500
  color: #2d2d2d
  padding: 0 3rem
  padding-top: 3.2rem
  padding-bottom: 2.4rem
.closeModal
  border-radius: 1rem
  background-color: #f2f2f2
  width: 100%
  padding: 1.6rem 2.4rem
.btn
  float: right
  background-color: transparent
  border-radius: 0.5rem
  border: solid 1px #ee8728
  padding: 0.4rem 5.4rem
  font-size: 1.4rem
  font-weight: bold
  line-height: 1.29
  letter-spacing: 0.1px
  text-align: center
  color: #ee8728

.BtnWrapper
  width: 5.6rem
  height: 5.6rem
  border-radius: 3.4rem
  box-shadow: 0 1.2rem 2.4rem 0 rgba(31, 4, 48, 0.1)
  background-color: #41126c
  position: fixed
  right: 2.9rem
  bottom: 2.7rem
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
.notificationNumber
  width: 5.6rem
  height: 5.6rem
  border-radius: 3.4rem
  box-shadow: 0 1.2rem 2.4rem 0 rgba(31, 4, 48, 0.1)
  background-color: #41126c
  position: fixed
  right: 2.9rem
  bottom: 13rem
  display: flex
  justify-content: center
  align-items: center
  font-size: 1.7rem
  font-weight: 900
  text-align: center
  color: #ffffff
  .img
    width: 2.4rem
    height: 2.4rem

#calltrap_btn
  background-color: #41126c
  -webkit-border-radius: 50%
  border-radius: 50%
  display: block
  height: 64px
  line-height: 100px
  opacity: 0.7
  position: fixed
  right: 35px
  top: 155px
  text-align: center
  width: 64px
  z-index: 9999
  -webkit-animation: calltrap_opacity 3s infinite linear
  -moz-animation: calltrap_opacity 3s infinite linear
  -o-animation: calltrap_opacity 3s infinite linear
  animation: calltrap_opacity 3s infinite linear

  &.calltrap_offline
    background-color: #41126c

#calltrap_ico
  width: 32px
  height: 32px
  position: absolute
  margin: auto
  top: 0px
  right: 0px
  bottom: 0px
  left: 0px
  border: 0
  -webkit-border-radius: 50% !important
  border-radius: 50% !important
  -o-background-clip: padding-box
  background-clip: padding-box
  -webkit-animation: uptocallmini 1.5s 0s ease-out infinite
  -moz-animation: uptocallmini 1.5s 0s ease-out infinite
  -o-animation: uptocallmini 1.5s 0s ease-out infinite
  animation: uptocallmini 1.5s 0s ease-out infinite

  &:before
    content: ""
    position: absolute
    width: 100%
    height: 100%
    background-size: cover
    top: 0
    left: 0
    background-image: url("../../../../assets/phone-callNav.svg")
    background-repeat: no-repeat
    background-position: center center
    -webkit-animation: calltrap_spin 3s infinite linear
    -moz-animation: calltrap_spin 3s infinite linear
    -o-animation: calltrap_spin 3s infinite linear
    animation: calltrap_spin 3s infinite linear

.calltrap_offline
  #calltrap_ico
    -webkit-animation: uptocallmini_offline 1.5s 0s ease-out infinite
    -moz-animation: uptocallmini_offline 1.5s 0s ease-out infinite
    -o-animation: uptocallmini_offline 1.5s 0s ease-out infinite
    animation: uptocallmini_offline 1.5s 0s ease-out infinite

#calltrap_overlay
  background-color: #191919
  background-color: rgba(25, 25, 25, 0.9)
  position: fixed
  z-index: 9998
  width: 100%
  height: 100%
  left: 0
  top: 0
  text-align: center
  display: none

  .b_calltrap_overlay
    display: table
    width: 100%
    height: 100%

  .b_calltrap_overlay__header, .b_calltrap_overlay__body, .b_calltrap_overlay__footer
    display: table-row
    padding: 0
    margin: 0

  .b_calltrap_overlay__header_block, .b_calltrap_overlay__body_block, .b_calltrap_overlay__footer_block
    display: table-cell
    vertical-align: middle
    text-align: center

  .b_b_calltrap_form__input__body-block
    padding: 0 7px

  .b_b_calltrap_form__input__header, .b_b_calltrap_form__input__footer
    height: 1px

  .b_calltrap_form__input
    background-color: white
    border: 5px solid #41126c
    -webkit-border-radius: 3px
    border-radius: 3px
    margin: 0
    padding: 0
    max-width: 500px
    display: block
    margin: 0 auto
    font-size: 16px
    line-height: 1.3
    position: relative
    font-family: Tahoma, Arial, self-serif !important

  .b_calltrap_form__input
    display: block
    position: absolute
    right: 20px
    top: 20px
    width: 20px
    height: 20px
    opacity: 0.5
    -webkit-transition: all 0.3s ease
    -moz-transition: all 0.3s ease
    -o-transition: all 0.3s ease
    transition: all 0.3s ease

    &:hover
      opacity: 1

@media screen and (min-width: 1px) and (max-width: 500px)
  #b_calltrap_form__input .b_calltrap_form__input
    width: 290px

#calltrap_success, #calltrap_form
  display: block

#calltrap_success .b_calltrap_form__input, #calltrap_form .b_calltrap_form__input
  margin: 20px 25px 35px

#calltrap_success .b_calltrap_form__input, #calltrap_form .b_calltrap_form__input
  font-size: 20px
  margin: 0 0 17px
  text-align: left

@media screen and (min-width: 1px) and (max-width: 500px)
  #calltrap_success .b_calltrap_form__input, #calltrap_form .b_calltrap_form__input
    padding-right: 20px

#calltrap_success .b_calltrap_form__input, #calltrap_form .b_calltrap_form__input
  text-align: left

#calltrap_success .b_calltrap_form__input p, #calltrap_form .b_calltrap_form__input p
  margin: 0 0 0.55em

#calltrap_success .b_calltrap_form__input, #calltrap_form .b_calltrap_form__input
  -webkit-box-sizing: border-box
  -moz-box-sizing: border-box
  box-sizing: border-box
  font-size: 16px
  padding: 0 15px 0
  height: 44px
  line-height: 40px
  background-color: white
  border: 2px solid #ccc
  -webkit-border-radius: 3px
  border-radius: 3px
  display: block
  width: 100%
  outline: none
  -webkit-transition: all 0.3s ease
  -moz-transition: all 0.3s ease
  -o-transition: all 0.3s ease
  transition: all 0.3s ease

@media screen and (min-width: 1px) and (max-width: 500px)
  #calltrap_success .b_calltrap_form__input, #calltrap_form .b_calltrap_form__input
    margin-bottom: 13px

#calltrap_success .b_calltrap_form__input.error, #calltrap_form .b_calltrap_form__input.error
  background-color: #ffc0c0
  color: red
  border-color: red

#calltrap_success .b_calltrap_form__input:disabled, #calltrap_form .b_calltrap_form__input:disabled
  background-color: #eee
  border-color: #bdbdbd
  cursor: default

#calltrap_success .b_calltrap_form__input:focus, #calltrap_form .b_calltrap_form__input:focus
  border: 2px solid #41126c

#calltrap_success .b_calltrap_form__submit, #calltrap_form .b_calltrap_form__submit
  -webkit-box-sizing: border-box
  -moz-box-sizing: border-box
  box-sizing: border-box
  background-color: #41126c
  border: 2px solid #41126c
  -webkit-border-radius: 3px
  border-radius: 3px
  border: medium none
  color: #fff
  cursor: pointer
  font-size: 16px
  padding: 0
  height: 44px
  line-height: 40px
  outline: medium none
  display: inline-block
  width: 180px
  text-align: center
  outline: none
  -webkit-transition: all 0.3s ease
  -moz-transition: all 0.3s ease
  -o-transition: all 0.3s ease
  transition: all 0.3s ease

// @media screen and (min-width: 1px) and (max-width: 500px)
//   #calltrap_success .b_calltrap_form__submit, #calltrap_form .b_calltrap_form__submit
//     width: 100%

#calltrap_success .b_calltrap_form__submit:hover, #calltrap_form .b_calltrap_form__submit:hover
  background-color: #41126c

#calltrap_success .b_calltrap_form__submit:disabled, #calltrap_form .b_calltrap_form__submit:disabled
  background-color: #bdbdbd !important
  cursor: default

#calltrap_success .b_calltrap_form__controls, #calltrap_form .b_calltrap_form__controls
  margin: 20px 0 0
  padding: 0
  padding: 0 200px 0 0

// @media screen and (min-width: 1px) and (max-width: 500px)
//   #calltrap_success .b-calltrap_form__controls, #calltrap_form .b_calltrap_form__controls
//     padding: 0

#calltrap_success .b_calltrap_form__controls_item, #calltrap_form .b_calltrap_form__controls_item
  position: relative
  float: right

// @media screen and (min-width: 1px) and (max-width: 500px)
//   #calltrap_success .b-calltrap_form__controls-item, #calltrap_form .b-calltrap_form__controls-item
//     float: none

#calltrap_success .b_calltrap_form__controls-left, #calltrap_form .b_calltrap_form__controls_left
  margin-left: -100%
  width: 100%

// @media screen and (min-width: 1px) and (max-width: 500px)
//   #calltrap_success .b-calltrap_form__controls-left, #calltrap_form .b-calltrap_form__controls-left
//     margin-left: 0

#calltrap_success .b_calltrap_form__controls_right, #calltrap_form .b_calltrap_form__controls_right
  margin-right: -200px
  overflow: visible
  width: 200px
  text-align: right

// @media screen and (min-width: 1px) and (max-width: 500px)
//   #calltrap_success .b-calltrap_form__controls-right, #calltrap_form .b-calltrap_form__controls-right
//     margin-right: 0
//     width: auto
//     text-align: left

#calltrap_success .b_calltrap_form__controls_clear, #calltrap_form .b_calltrap_form__controls_clear
  clear: both

#calltrap_success
  display: none

#calltrap_overlay.calltrap_offline
  .b_calltrap_form__submit
    background-color: #41126c

    &:hover
      background-color: #2e8c1c

  .b_calltrap_form__input:focus, .b_calltrap__container
    border-color: #41126c

@-webkit-keyframes calltrap_opacity
  0%
  opacity: 1

  57%
  opacity: 0.75

  75%
  opacity: 0.95

  100%
  opacity: 1

@-moz-keyframes calltrap_opacity
  0%
  opacity: 1

  57%
  opacity: 0.75

  75%
  opacity: 0.95

  100%
  opacity: 1

@-o-keyframes calltrap_opacity
  0%
  opacity: 1

  57%
  opacity: 0.75

  75%
  opacity: 0.95

  100%
  opacity: 1

@keyframes calltrap_opacity
  0%
    opacity: 1

  57%
    opacity: 0.75

  75%
    opacity: 0.95

  100%
    opacity: 1

@-webkit-keyframes calltrap_spin
  0%
  -webkit-transform: rotate(0deg)
  transform: rotate(0deg)

  30%
  -webkit-transform: rotate(0deg)
  transform: rotate(0deg)

  33%
  -webkit-transform: rotate(-10deg)
  transform: rotate(-10deg)

  36%
  -webkit-transform: rotate(10deg)
  transform: rotate(10deg)

  39%
  -webkit-transform: rotate(-10deg)
  transform: rotate(-10deg)

  42%
  -webkit-transform: rotate(10deg)
  transform: rotate(10deg)

  45%
  -webkit-transform: rotate(-10deg)
  transform: rotate(-10deg)

  48%
  -webkit-transform: rotate(10deg)
  transform: rotate(10deg)
  opacity: 1

  51%
  -webkit-transform: rotate(-10deg)
  transform: rotate(-10deg)

  54%
  -webkit-transform: rotate(10deg)
  transform: rotate(10deg)

  57%
  -webkit-transform: rotate(0deg)
  transform: rotate(0deg)

@-moz-keyframes calltrap_spin
  0%
  -moz-transform: rotate(0deg)
  transform: rotate(0deg)

  30%
  -moz-transform: rotate(0deg)
  transform: rotate(0deg)

  33%
  -moz-transform: rotate(-10deg)
  transform: rotate(-10deg)

  36%
  -moz-transform: rotate(10deg)
  transform: rotate(10deg)

  39%
  -moz-transform: rotate(-10deg)
  transform: rotate(-10deg)

  42%
  -moz-transform: rotate(10deg)
  transform: rotate(10deg)

  45%
  -moz-transform: rotate(-10deg)
  transform: rotate(-10deg)

  48%
  -moz-transform: rotate(10deg)
  transform: rotate(10deg)
  opacity: 1

  51%
  -moz-transform: rotate(-10deg)
  transform: rotate(-10deg)

  54%
  -moz-transform: rotate(10deg)
  transform: rotate(10deg)

  57%
  -moz-transform: rotate(0deg)
  transform: rotate(0deg)

@-o-keyframes calltrap_spin
  0%
  -o-transform: rotate(0deg)
  transform: rotate(0deg)

  30%
  -o-transform: rotate(0deg)
  transform: rotate(0deg)

  33%
  -o-transform: rotate(-10deg)
  transform: rotate(-10deg)

  36%
  -o-transform: rotate(10deg)
  transform: rotate(10deg)

  39%
  -o-transform: rotate(-10deg)
  transform: rotate(-10deg)

  42%
  -o-transform: rotate(10deg)
  transform: rotate(10deg)

  45%
  -o-transform: rotate(-10deg)
  transform: rotate(-10deg)

  48%
  -o-transform: rotate(10deg)
  transform: rotate(10deg)
  opacity: 1

  51%
  -o-transform: rotate(-10deg)
  transform: rotate(-10deg)

  54%
  -o-transform: rotate(10deg)
  transform: rotate(10deg)

  57%
  -o-transform: rotate(0deg)
  transform: rotate(0deg)

@keyframes calltrap_spin
  0%
    -webkit-transform: rotate(0deg)
    -moz-transform: rotate(0deg)
    -o-transform: rotate(0deg)
    transform: rotate(0deg)

  30%
    -webkit-transform: rotate(0deg)
    -moz-transform: rotate(0deg)
    -o-transform: rotate(0deg)
    transform: rotate(0deg)

  33%
    -webkit-transform: rotate(-10deg)
    -moz-transform: rotate(-10deg)
    -o-transform: rotate(-10deg)
    transform: rotate(-10deg)

  36%
    -webkit-transform: rotate(10deg)
    -moz-transform: rotate(10deg)
    -o-transform: rotate(10deg)
    transform: rotate(10deg)

  39%
    -webkit-transform: rotate(-10deg)
    -moz-transform: rotate(-10deg)
    -o-transform: rotate(-10deg)
    transform: rotate(-10deg)

  42%
    -webkit-transform: rotate(10deg)
    -moz-transform: rotate(10deg)
    -o-transform: rotate(10deg)
    transform: rotate(10deg)

  45%
    -webkit-transform: rotate(-10deg)
    -moz-transform: rotate(-10deg)
    -o-transform: rotate(-10deg)
    transform: rotate(-10deg)

  48%
    -webkit-transform: rotate(10deg)
    -moz-transform: rotate(10deg)
    -o-transform: rotate(10deg)
    transform: rotate(10deg)
    opacity: 1

  51%
    -webkit-transform: rotate(-10deg)
    -moz-transform: rotate(-10deg)
    -o-transform: rotate(-10deg)
    transform: rotate(-10deg)

  54%
    -webkit-transform: rotate(10deg)
    -moz-transform: rotate(10deg)
    -o-transform: rotate(10deg)
    transform: rotate(10deg)

  57%
    -webkit-transform: rotate(0deg)
    -moz-transform: rotate(0deg)
    -o-transform: rotate(0deg)
    transform: rotate(0deg)

@-webkit-keyframes uptocallmini
  0%
  -webkit-box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0)
  box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0)

  10%
  -webkit-box-shadow: 0 0 8px 6px #41126c, 0 0 12px 10px transparent, 0 0 12px 14px #41126c
  box-shadow: 0 0 8px 6px #41126c, 0 0 12px 10px transparent, 0 0 12px 14px #41126c

  100%
  -webkit-box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0)
  box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0)

@-moz-keyframes uptocallmini
  0%
  box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0)

  10%
  box-shadow: 0 0 8px 6px #41126c, 0 0 12px 10px transparent, 0 0 12px 14px #41126c

  100%
  box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0)

@-o-keyframes uptocallmini
  0%
  box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0)

  10%
  box-shadow: 0 0 8px 6px #41126c, 0 0 12px 10px transparent, 0 0 12px 14px #41126c

  100%
  box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0)

@keyframes uptocallmini
  0%
    -webkit-box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0)
    box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0)

  10%
    -webkit-box-shadow: 0 0 8px 6px #41126c, 0 0 12px 10px transparent, 0 0 12px 14px #41126c
    box-shadow: 0 0 8px 6px #41126c, 0 0 12px 10px transparent, 0 0 12px 14px #41126c

  100%
    -webkit-box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0)
    box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0)

@-webkit-keyframes uptocallmini_offline
  0%
  -webkit-box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(53, 161, 32, 0)
  box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(53, 161, 32, 0)

  10%
  -webkit-box-shadow: 0 0 8px 6px #41126c, 0 0 12px 10px transparent, 0 0 12px 14px #41126c
  box-shadow: 0 0 8px 6px #41126c, 0 0 12px 10px transparent, 0 0 12px 14px #41126c

  100%
  -webkit-box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(53, 161, 32, 0)
  box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(53, 161, 32, 0)

@-moz-keyframes uptocallmini_offline
  0%
  box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(53, 161, 32, 0)

  10%
  box-shadow: 0 0 8px 6px rgba(65, 18, 108 , 0.5), 0 0 12px 10px transparent, 0 0 12px 14px #41126c

  100%
  box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(53, 161, 32, 0)

@-o-keyframes uptocallmini_offline
  0%
  box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(53, 161, 32, 0)

  10%
  box-shadow: 0 0 8px 6px rgba(65, 18, 108 , 0.5), 0 0 12px 10px transparent, 0 0 12px 14px #41126c

  100%
  box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(53, 161, 32, 0)

@keyframes uptocallmini_offline
  0%
    -webkit-box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(53, 161, 32, 0)
    box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(53, 161, 32, 0)

  10%
    -webkit-box-shadow: 0 0 8px 6px rgba(65, 18, 108 , 0.5), 0 0 12px 10px transparent, 0 0 12px 14px rgba(65, 18, 108 , 0.5)
    box-shadow: 0 0 8px 6px rgba(65, 18, 108 , 0.5), 0 0 12px 10px transparent, 0 0 12px 14px rgba(65, 18, 108 , 0.5)

  100%
    -webkit-box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(53, 161, 32, 0)
    box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(53, 161, 32, 0)
