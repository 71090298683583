.wrapper
  // position: fixed
  // width: 36rem !important
  width: 100%
  background-color: #ffffff
  box-shadow: 0.1rem 0.3rem 1.4rem 0 rgba(31, 4, 48, 0.1)
  padding: 1.5rem 2.4rem
  .content
    text-transform: uppercase
    font-size: 1.4rem
    font-weight: 900
    color: #595959
