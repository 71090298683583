.wrapper,.mobileWrapper
  width: 47rem
  border-radius: 5px
  box-shadow: 3.4rem 5.5rem 6.9rem 0 rgba(31, 4, 48, 0.1)
  background-color: #ffffff
  margin-left: 10px
  margin-bottom: 2.1rem
  height: 100%
  .address
    display: flex
    justify-content: space-between
    flex-direction: row
    padding: 0 2.3rem
    padding-bottom: 2.3rem
    .zone
      display: flex
      flex-direction: column
      .zoneTitle
        font-size: 1.2rem
        color: #595959
      .zoneSubTitle
        font-size: 1.6rem
        font-weight: 900
        color: #1a1a1a
  .itemsWrapper
    padding: 2.3rem 2.3rem 0 2.3rem
  .header
    display: flex
    flex-direction: column
    padding: 2.3rem
    justify-content: space-between
    .title
      font-size: 1.8rem
      font-weight: 500
      color: #1a1a1a
    .btn
      font-size: 1.6rem
      font-weight: 500
      line-height: 1.13
      letter-spacing: 0.11px
      text-align: center
      color: #41126c
      background-color: transparent !important
      outline: none !important

      p
        border-bottom: 1px solid #41126c
    .btnIcon
      width: 2.4rem
      height: 2.4rem
      border-radius: 1.2rem
      background-color: rgba(65,18,108, 0.35)
      margin: 0 0.7rem

  .payment
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: space-between
    align-items: baseline !important
    border-top: 0.2rem solid rgba(31, 4, 48, 0.1)
    border-bottom: 0.2rem solid rgba(31, 4, 48, 0.1)
    width: 100%
    padding: 2.3rem
    .maskedPromoWrapper
      display: flex
      flex-direction: column
      .maskedPromoTitle
        padding: 25px 0 0 0
        font-size: 12px
        color: #65656c
    .promotionWrapper
      .hiddenPromoWrapper
        margin-top: 0.2rem
        border-radius: 4px
        padding: 0.2rem 0rem
        text-align: left
        .hiddenPromo
          text-align: left
          font-size: 1.4rem
          font-weight: bold
          color: #F09440
      .promotionTitle
        font-size: 1.4rem
        font-weight: 900
        color: #1a1a1a
      .promo
        font-size: 1.2rem
        font-weight: 500
        line-height: 1.25
        color: #41126c
        margin: .5rem 0
      .userPromoTitle
        font-size: 12px
        color: #65656c
        margin-bottom: 6px
      .maskedPromoCode
        background-color: #f2f2f2
        height: 2.3rem
        border-radius: 4px
        font-size: 1.4rem
        color: #000
        display: flex !important
        align-items: center !important
        justify-content: center !important
        text-transform: uppercase
      .promoCodeWrapper
        display: flex
        flex-direction: row
        .helpIcon
          height: 12px !important
          width: 12px !important
        .tooltip
          .tip
            background-color: #000
            color: #fff
            max-width: 22rem
            font-size: 1rem
            border: 1px solid #dadde9
            padding: 2rem

        .promoCode

          // width: 5.4rem
          height: 2.3rem
          border-radius: 4px
          background-color: rgba(65, 18, 108, 0.2)
          font-size: 1.4rem
          color: #41126c
          padding: 0 .5rem
          align-items: center
          text-transform: uppercase

    .method
      width: 50%
      margin-top: 1.8rem
    .method , .invoice
      width: 44%
      span
        font-size: 1.4rem
        font-weight: 900
        color: #1a1a1a
      .beforePromo
        font-size: 1.2rem
        color: #a6a6a6
        text-decoration: line-through
      .totalInvoiceTitle
        font-size: 1.6rem
        color: #1a1a1a
      .paymentTitle
        font-size: 1.6rem
        position: relative
        color: #1a1a1a
        font-weight: lighter
        text-transform: capitalize
      p
        font-size: 1.6rem
        color: #1a1a1a
  .btnWrapper
    display: flex
    flex-direction: row
    justify-content: space-around
    align-items: center
    padding: 2.4rem 6.8rem

    .accept, .btnDisabled
      width: 14.4rem
      height: 4.8rem
      outline: 0
      border-radius: 5px
      background-color: #38c172
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      text-align: center
      color: #ffffff
    .btnDisabled
      cursor: not-allowed
      background-color: #cccccc
    .refundingBtn, .disabledRefundBtn
      display: flex
      align-items: center
      padding: 10px 12px
      width: auto
      height: 42px
      border-radius: 4px
      border: solid 1px #ffa351
      font-size: 14px
      font-weight: bold
      color: #ffa351
    .disabledRefundBtn
      cursor: not-allowed
      opacity: 0.66
    .reject, .disabled
      width: 14.4rem
      height: 4.8rem
      border-radius: 5px
      background-color: #db3030
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      outline: 0
      text-align: center
      color: #ffffff
    .disabled
      cursor: not-allowed
      background-color: #cccccc
  .noShowWrapper
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 2.4rem
    .noShowBtn
      width: 14.4rem
      height: 4.8rem
      border-radius: 5px
      background-color: #db3030
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      text-align: center
      color: #ffffff
      outline: 0

.mobileWrapper
  width: 100%
  margin-left: 0
  .payment
    .promotionWrapper
      .promoCodeWrapper

  .header
    .btn
      display: flex
      justify-content: flex-end

.alertBox
  align-self: stretch
  flex-grow: 0
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: stretch
  gap: 8px
  padding: 12px
  font-size: 15px
  border-radius: 4px
  border: solid 1px #ee5253
  background-color: #fdeaea
  color: #ee5253
  ul
    padding-left: 45px
.refundDetails
  p
    font-size: 1.8rem
    margin-top: 5px
    margin-bottom: 0px
