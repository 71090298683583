
.main
  display: flex
  justify-content: center
  align-items: center
  .wrapper
    width: 56.8rem
    border-radius: 1rem
    box-shadow: 0 0 1.6rem 0 rgba(0, 0, 0, 0.12), 0 1.6rem 1.6rem 0 rgba(0, 0, 0, 0.13)
    background-color: #ffffff
    display: flex
    flex-direction: column
    justify-content: center
    .title
      padding: 2.4rem
      font-size: 2.2rem
      font-weight: 500
      color: #2d2d2d
    .btnWrapper
      width: 100%
      height: 8rem
      border-radius: 1rem
      display: flex
      flex-direction: row
      justify-content: flex-end
      align-items: center
      padding: 1.6rem 2.4rem
      background-color: #f2f2f2
      .edit
        width: 14.4rem
        height: 4.8rem
        border-radius: .5rem
        background-color: #f09440
        font-size: 1.4rem
        font-weight: bold
        line-height: 1.29
        letter-spacing: 0.1px
        text-align: center
        margin-left: .8rem
        color: #ffffff
        outline: none
      .cancel
        width: 14.4rem
        height: 4.8rem
        border-radius: .4rem
        border: solid 1px #ee8728
        background-color: #f2f2f2
        outline: none
        font-size: 1.4rem
        font-weight: bold
        line-height: 1.29
        letter-spacing: 0.1px
        text-align: center
        color: #ee8728
    .unavailabilityStatusWrapper
      display: flex
      flex-direction: column
      margin-bottom: 2.4rem
      margin-right: 2.4rem
      margin-left: 2.4rem
      .unavailabilityStatusTitle
        font-size: 1.6rem
        margin-bottom: 1rem
        font-weight: bold
        color: #2d2d2d
.selectItem
  width: 100%
  height: 5.5rem
  border-radius: .5rem
  box-shadow: inset .1rem .1rem .2rem 0 rgba(0, 0, 0, 0.1)
  border: solid .1rem #a6a6a6
  background-color: #ffffff
  padding: 1.6rem
  .dropdownTitle
    font-size: 1.6rem
    color: #1a1a1a
  .icon
    width: 1.5rem
    height: 1.5rem
.itemSelected
  height: 4rem
  display: flex
  align-items: center
