.main
  display: flex
  justify-content: center
  align-items: center
  .wrapper
    width: 58.4rem
    height: 30.3rem
    border-radius: 10px
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.13)
    background-color: #ffffff
    display: flex
    flex-direction: column
    outline: none !important
    .btnWrapper
      width: 100%
      height: 8rem
      border-radius: 0 0 1rem 1rem
      background-color: #f2f2f2
      display: flex
      flex-direction: row
      justify-content: flex-end
      align-items: center
      padding: 0  2.4rem
      .refund, .cancel
        width: 14.4rem
        height: 4.8rem
        border-radius: 5px
        background-color: #db3030
        font-size: 1.4rem
        font-weight: bold
        line-height: 1.29
        letter-spacing: 0.1px
        text-align: center
        color: #ffffff
        outline: none
      .cancel
        margin: 0 .8rem
        border: solid 1px #ee8728
        background-color: #f2f2f2
        color: #ee8728
    .headerContent
      display: flex
      flex-direction: column
      padding: 3.2rem
      .title
        font-size: 2.2rem
        font-weight: 500
        margin-bottom: 2.2rem
        color: #2d2d2d
      .alarmWrapper
        width: 52rem
        height: 10.4rem
        border-radius: 5px
        display: flex
        flex-direction: row
        align-items: center
        padding: 2.4rem
        background-color: rgba(219, 48, 48, 0.2)
        .alarmTitle
          margin: 0 2.4rem
          font-size: 2.1rem
          font-weight: 500
          color: #db3030
