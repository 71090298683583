.main
  display: flex
  flex-direction: column
  .wrapper, .inActiveWrapper
    width: 100%
    margin: 0.8rem 2.4rem
    height: 7.2rem
    box-shadow: 0 .6rem 1.2rem 0 rgba(31, 4, 48, 0.1)
    background-color: #ffffff
    padding: 1.6rem 2.4rem
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    align-self: center
    .statusWrapper
      display: flex
      flex-direction: row
      .branchStatusWrapper
        opacity: 1
        align-items: center
        justify-content: center
        // transition: all 2s
        display: flex
        flex-direction: row
      .separator
        margin: 0 .4rem
      .line
        opacity: 1
        width: .8rem
        display: flex
        align-self: center
        margin: 0 .8rem
        height: .1rem
        background-color: #d8d8d8
      .statusLabelWrapper
        display: flex
        flex-direction: column
        justify-content: center
        .version
          display: flex !important
          justify-content: flex-end !important
          font-size: 1.2rem
          font-weight: 500
          color: #1a1a1a
        .labelWrapper
          display: flex
          flex-direction: row
          align-items: center
          .statusLabel
            font-size: 1.4rem
            font-weight: 900
            color: #38c172
          .dot
            margin: 0 .2rem
            width: .8rem
            height: .8rem
            border-radius: .4rem
            background-color: #38c172
    .titleWrapper
      display: flex
      flex-direction: column
      .title
        font-size: 1.6rem
        font-weight: 900
        color: #1a1a1a
      .disconnectedWrapper
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        .disconnectedLabel
          font-size: 1.2rem
          color: #595959
        .time
          font-size: 1.2rem
          font-weight: 900
          color: #595959
          margin: 0 .1rem
    &:hover
      .branchStatusWrapper
        opacity: 1
        align-items: center
        justify-content: center
        // transition: all 2s
        display: flex
        flex-direction: row
      .separator
        margin: 0 .4rem
      .line
        // transition: all 2s
        opacity: 1
        width: .8rem
        display: flex
        align-self: center
        margin: 0 .8rem
        height: .1rem
        background-color: #d8d8d8
  .inActiveWrapper
    border-left: .3rem solid  #db3030
    .statusWrapper
      .statusLabelWrapper
        .labelWrapper
          .statusLabel
            color: #db3030
          .dot
            background-color: #db3030
