.modalWrapper
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    .modalBody
        width: 550px
        height: 425px
        border-radius: 10px
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.13)
        background-color: #fff
        .header
            width: 100%
            height: 100px
            border-radius: 10px 10px 0px 0px
            background: #F09440
        .body
            transform: translateY(-80px)
            display: flex
            flex-direction: column
            align-items: center
            height: calc(425px - 100px - 80px)
            .image
                width: 120px
                height: 120px
                border-radius: 50%
                margin: 0 15px 10px 9px
                box-shadow: inset 0 0 0 0 #595959
            .name
                margin: 10px 0 0
                font-size: 22px
                font-weight: 900
                color: #2d2d2d
                text-transform: capitalize
            .type
                font-size: 14px
                font-weight: 500
                text-align: center
                color: #1a1a1a
                margin-top: 12px
            .amountWrapper
                width: 150px
                height: 56px
                margin: 3rem 0px
                padding: 12px 16px
                border-radius: 4px
                border: solid 1px #f09440
                display: flex
                justify-content: space-between
                align-items: center
                .amount
                    font-size: 1.3rem
                    margin-top: .35rem
                    font-weight: 900
                    margin-left: 10px
        .footer
            width: 100%
            height: 80px
            margin: 42px 0 0
            padding: 16px 24px 16px 0px
            background-color: #f2f2f2
            display: flex
            justify-content: flex-end
            .cancel
                height: 48px
                margin: 0 8px 0 0
                padding: 15px 50px
                border-radius: 4px
                border: solid 1px #ee8728
                color: #ee8728
                font-size: 1.3rem
                outline: none
            .send
                height: 48px
                width: 144px
                max-width: 100%
                margin: 0 0 0 8px
                border-radius: 5px
                background-color: #f09440
                color: white
                font-size: 1.3rem
                display: flex
                justify-content: center
                align-items: center
                outline: none
