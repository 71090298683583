.main
  display: flex
  justify-content: center
  align-items: center
  .wrapper
    display: flex
    flex-direction: column
    width: 50.8rem
    border-radius: 1rem
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.13)
    background-color: #ffffff
    outline: none
    .footer
      width: 100%
      height: 8rem
      border-radius: 1rem
      background-color: #f2f2f2
      display: flex
      flex-direction: row
      justify-content: flex-end
      padding: 0 2.4rem
      align-items: center
      .editReasonBtn, .cancelBtn, .disabled
        display: flex
        align-items: center
        justify-content: center
        width: 15.7rem
        height: 4.8rem
        border-radius: 5px
        background-color: #ee8728
        font-size: 1.4rem
        font-weight: bold
        line-height: 1.29
        letter-spacing: 0.1px
        text-align: center
        color: #ffffff
        outline: none
      .disabled
        background-color: #a6a6a6
      .cancelBtn
        width: 14.4rem
        margin: 0 1rem
        color: #ee8728
        background-color: #f2f2f2
        border-radius: 4px
        border: solid 1px #ee8728

    .contentWrapper
      display: flex
      flex-direction: column
      padding: 3.2rem
      .title
        font-size: 2.2rem
        font-weight: 500
        color: #2d2d2d
      .dropdownWrapper
        display: flex
        flex-direction: column
        margin-top: 2.6rem
        margin-bottom: 3.2rem
        .label
          margin-bottom: .5rem
          font-size: 16px
          font-weight: bold
          color: #2d2d2d
.selectItem
  width: 100%
  padding: 0 1.6rem
  height: 5.5rem
  display: flex
  flex-direction: row
  justify-content: space-between
  border-radius: 5px
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
  border: solid 1px #a6a6a6
  background-color: #ffffff
  .dropdownTitle
    font-size: 1.6rem
    color: #1a1a1a
.itemSelected
  display: flex
  flex-direction: row
  height: 5.5rem
  align-items: center
  width: 100%
