.loadingWrapper
  width: 100%
  position: absolute
  top: 0
  right: 0
  left: 0
  bottom: 0
  flex: 1
  .override
    display: block
    margin: 0 auto
    border-color: red
