.closeWrapper
  right: 1.5rem
.closeWrapperRtl
  left: 1.5rem
.close
  margin: 0
  width: 1rem
  height: 1rem
.wrongCode, .rightCode, .networkWrapper
  display: flex
  min-width: 28rem
  min-height: 6rem
  border-radius: 5px
  box-shadow: 0 12px 24px 0 rgba(0, 23, 59, 0.1)
  background-color: #db3030
  padding: 1.1rem 1.6rem
  align-items: center
  z-index: 4000

  img
    width: 3rem
    height: 3rem
    margin: 0 .5rem
  .refresh
    font-size: 14px
    font-weight: 900
    text-decoration: underline
    letter-spacing: 0.32px
    color: #ffffff
  .content
    margin: 0 2rem
    font-size: 1.4rem
    font-weight: 900
    letter-spacing: 0.32px
    color: #ffffff
.networkWrapper
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  padding: 0 16px
  .refresh
    font-size: 14px
    font-weight: 900
    text-decoration: underline
    letter-spacing: 0.32px
    color: #ffffff
    cursor: pointer
  .contentWrapper
    display: flex
    flex-direction: row
    align-items: center
    margin-right: 167px
    .networkError
      margin-left: 16px
      font-size: 14px
      font-weight: 900
      letter-spacing: 0.32px
      color: #ffffff
.rightCode
  background-color: #38c172
.rtl
  direction: rtl !important
  .wrongCode, .rightCode
    border-left: 0
  *
    direction: rtl !important
    text-align: right
