@import ../../../sass/colors

.dropDownPart
  display: flex
  flex-direction: column
  margin: 0
  span
    font-size: 1.6rem
    color: #222222
    position: relative
    margin: 0
  .switch
    font-size: 1.6rem !important
    margin: 0 !important
    width: 100%
    border-radius: 5px !important
    border: solid 1px #ebebeb !important
    outline: none !important
    background-color: white !important
    text-align: left !important
    padding: 0 !important
    font-weight: 500 !important
    color: #222222 !important
    box-shadow: none !important
    div
      display: flex !important
      align-items: center !important
      justify-content: space-between !important

  .switch:disabled
    background-color: #F3F3F3 !important
    cursor: default

.menuWrapper
  max-height: 30rem
  overflow: auto
  min-width: 100% !important
  .item
    padding: 1rem
    text-align: center
    font-size: 1.8rem
    outline: 0 !important
