.headerWrapper
  width: 100%
  height: 3.2rem
  background-color: #f2f2f2
  padding: .8rem 2.4rem
  margin: 0.8rem 0
  .headerTitle
    font-size: 1.2rem
    font-weight: 900
    color: #a6a6a6
