
.dropDownPart
  display: flex
  flex-direction: column
  margin: 0
  span
    font-size: 1.6rem
    color: #222222
    position: relative
    margin: 0
  .switch
    font-size: 1.6rem !important
    margin: 0 !important
    height: 100%
    width: 100%
    border-radius: 5px !important
    border: solid 1px #ebebeb !important
    outline: none !important
    background-color: white !important
    text-align: left !important
    padding: 0 !important
    font-weight: 500 !important
    color: #222222 !important
    box-shadow: none !important
    div
      display: flex !important
      align-items: center !important
      justify-content: space-between !important

  .switch:disabled
    background-color: #F3F3F3 !important
    cursor: default

.menuWrapper
  max-height: 30rem
  overflow: auto
  min-width: 100% !important
  margin: 5px 0
  border-radius: 5px
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.15)
  padding: 0
  .searchWrapper
    width: 100%
    border-radius: 5px
    padding: 12px 16px 8px
    background-color: #f2f2f2
    .inputWrapper
      display: flex
      flex-direction: row
      justify-content: space-between
      padding: 1px 0
      align-items: center
      width: 100% !important
      height: 48px !important
      background-color: #ffffff
      border-radius: 5px
      box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.15)
      border: solid 1px #ebebeb
      background-color: #ffffff
      .icon
        padding: 14px 15px
      .input
        height: 90%
        width: 100%
        outline: none
        border: none
        padding: 16px
        font-size: 16px
        line-height: 0.88
      .input::placeholder
        font-size: 16px
        line-height: 0.88
        color: #a6a6a6

  button
    display: flex
    align-items: center
    width: 100%
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.15)
    border: 0 none
    padding: 0 !important
    outline: none
    background-color: #ffffff
    .item
      padding: 16px
      font-size: 18px
      outline: 0 !important
.emptyWrapper
  height: 146px
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  .emptyTitle, .value
    font-size: 16px
    color: #a6a6a6
  .value
    color: #1a1a1a
