.wrapper
  .title
    width: 3.2rem
    height: 3.2rem
    border-radius: 1.6rem
    background-color: rgba(65, 18, 108, 0.2)
    position: relative
  .customizeWrapper
    width: 12rem
    background-color: rgba(65, 18, 108 , 0.35)
    padding: 1.25rem
    flex: 1
    display: flex
    flex-direction: row
    height: 100%
    align-items: center
    .customizeTitle
      font-size: 1.2rem
      font-weight: 900
      letter-spacing: 0.6px
      color: #41126c
    img
      width: 1.6rem
      height: 1.6rem
      margin: auto

  .time
    padding-left: 4rem
  & > span
    flex: 1

.options
  z-index: 4444 !important
  position: absolute
  left: 20rem
  top: 6rem
  width: 13rem
  // height: 11.79rem
  border-radius: 0.2rem
  box-shadow: 0 0.4rem 1.2rem 0 rgba(31, 4, 48, 0.1)
  background-color: #ffffff
  .optionsWrapper
    padding: 2.4rem 1.6rem
  label
    font-size: 1.2rem
    font-weight: 500
    color: #a6a6a6
    &:hover
      color: #1a1a1a !important
    &:active
      color: #1a1a1a !important
    &:focus
      color: #1a1a1a !important
    &:visited
      color: #1a1a1a !important
    &:checked
      color: #1a1a1a !important

.show
  display: block
  transition: opacity 0.4s linear
  transform: translate(0)
.hide
  transition: opacity 0.4s linear
  transform: translate(0)
  display: none

.optionWrapper
  display: flex !important
  align-items: center !important
  padding: 0 1.6rem
  padding-bottom: 1.6rem
  cursor: pointer
  .optionInputWrapper
    width: 1.6rem
    height: 1.6rem
    border-radius: 0.8rem
    border: solid 1px #a6a6a6
    display: flex !important
    align-items: center !important
    justify-content: center !important
    .optionInput
      width: 1.1rem
      height: 1.1rem
      border-radius: 0.8rem
  .optionText
    font-size: 1.3rem
    font-weight: 500
    padding: 0 0.4rem
  // &:hover
  //   .optionText
  //     color: #1a1a1a !important

  &:hover
    .optionText
      color: #1a1a1a !important

  // &:focus
  //   .optionText
  //     color: #1a1a1a !important
  // &:visited
  //   .optionText
  //     color: #1a1a1a !important
  // &:checked
  //   .optionText
  //     color: #1a1a1a !important

.activeText
  color: #1a1a1a
.inactiveText
  color: #a6a6a6

.activeOption
  background-color: #f09440

.inactiveOption
  background-color: #ffffff
  &:hover
    background-color: rgba(240, 148, 64, 0.5)
