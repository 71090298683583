.wrapper, .mobileWrapper
  width: 27rem
  border-radius: 5px
  box-shadow: 3.4rem 5.5rem 6.9rem 0 rgba(31, 4, 48, 0.1)
  background-color: #ffffff
  display: flex
  flex-direction: column
  margin-bottom: 1rem
  min-height: auto
  padding-bottom: 20rem
  overflow: scroll
  overflow-x: hidden
  overflow-y: hidden
  position: relative
  .rejectionReason
    width: 23.2rem
    // height: 5.6rem
    border-radius: 5px
    border: solid 1px #db3030
    background-color: rgba(219, 48, 48, 0.1)
    display: flex
    flex-direction: row
    position: absolute
    padding: 1rem
    left: 2.4rem
    bottom: 2.6rem
    .reasonWrapper
      display: flex
      flex-direction: column
      margin-left: .8rem
      .reasonsWrapper
        display: flex
        flex-direction: row
        justify-content: space-between
        .editReason
          outline: none
          font-size: 1.2rem
          font-weight: 900
          color: #db3030
          text-decoration: underline
          cursor: pointer
          background: none
        .reasonTitle
          font-size: 1.2rem
          font-weight: 900
          padding: 0 68px 0 0
          color: #db3030
      .reason
        font-size: 1.2rem
        font-weight: 500
        color: #db3030
  .title
    font-size: 1.8rem
    font-weight: 500
    color: #1a1a1a
    padding: 2.4rem
  .progressItem
    width: 27rem
    display: flex
    // align-self: center
    flex-direction: column
    align-items: flex-start
    padding: 0 2.4rem
    .finlay
      display: flex
      justify-content: space-between
      height: 1.8rem
      position: absolute
      right: 2rem
    .link
      text-decoration: none
      cursor: alias
      display: flex !important
      justify-content: space-between !important
      height: 1.8rem !important
      .errorsIcon
         width: 2.4rem
         height: 2.4rem
    .img
      width: 2.4rem
      height: 2.4rem
    .dot
      transition: .2s all
      width: 1.8rem
      height: 1.8rem
      border-radius: 50%
      background-color: #a6a6a6
      display: flex !important
      &:active
        background-color: #ffffff
    .orderStateWrapper
      display: flex
      justify-content: center
      flex-direction: column
      width: 100%
      align-items: flex-start
      padding: 4.8rem 2rem
      .orderState
        padding: 1.3rem 0 0 0
        font-size: 1.8rem
        font-weight: 900
        letter-spacing: 0.23px
        color: #1a1a1a
        display: flex !important
      .time
        font-size: 1.4rem
        font-weight: 500
        width: 15rem
        letter-spacing: 0.23px
        color: #a6a6a6
      .country
        font-size: 1.4rem
        font-weight: 500
        letter-spacing: 0.23px
        color: #f09440
    .line, .lineForState, .acceptedLine
      transition: .2s all
      display: flex
      justify-content: center
      width: .4rem
      min-height: 11rem
      margin: .5rem 0 0 1rem
    &:last-child
      .line, .lineForState, .acceptedLine
        display: none
.userName
  font-size: 1.2rem
  text-transform: uppercase
  text-align: right
  color: #ffffff

.mobileWrapper
  width: 100%
  .progressItem
    width: 100%
    .link
      height: 11rem !important
    .orderStateWrapper
      justify-content: flex-start
      padding: 0 2rem
