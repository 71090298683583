
.wrapper
  width: 100%
  height: 71.4rem
  border-radius: 1rem
  border: solid 1px #e6e6e6
  background-color: #ffffff
  padding: 2.4rem 0
  flex: 2
  .content
    display: flex
    flex-direction: column
    align-items: center
  .nothingHere
    display: flex !important
    justify-content: center !important
    align-items: center
    height: 50%
    width: 100%
    .placeholderTitle
      font-size: 2rem
      flex: 1
      display: flex !important
      color: #000000
      align-items: center !important
      justify-content: center !important
      width: 100%
      height: 100%
  .branchWrapper
    display: flex
    flex-direction: column
    height: 94%
    overflow-x: hidden !important
    overflow: auto
  .branchWrapper::-webkit-scrollbar
    width: 8px
  .branchWrapper::-webkit-scrollbar-thumb
    background-color: #888
    border-radius: 20px
  .header
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 0 2.6rem
    padding-bottom: 2.4rem
    .title
      font-size: 2.2rem
      font-weight: 500
      color: #1a1a1a
    .searchWrapper
      align-items: center
      justify-content: center
      display: flex
      flex-direction: row
      .search
        display: flex
        flex-direction: row
        justify-content: space-between
        width: 34.7rem
        height: 4.8rem
        border-radius: .5rem
        border: solid 1px #ebebeb
        background-color: #ffffff
        padding: 1.5rem
        align-items: center
        .searchIcon
          width: 2rem
          height: 2rem
        .input
          padding: 0 .25rem
          height: 4rem
          width: 30rem
          outline: 0
          border: 0
          font-size: 1.6rem
          line-height: 0.88
          color: #9b9b9b
        .input::placeholder
          font-size: 1.6rem
          line-height: 0.88
          color: #9b9b9b
