
.header
  height: 7rem
  background-color: #fff
  right: 0
  top: 0
  width: 100%
  position: fixed
  display: flex
  justify-content: space-between
  padding: 0 6rem 0 18rem
  align-items: center
  z-index: 11000000000
  @media screen and ( max-width: 1440px )
    padding: 0 6rem 0 6rem

  .headerRightSide
    display: flex
    justify-content: space-around
    align-items: center
    width: 35%
  .search
    display: flex
    align-items: center
    .logo
      margin: 0 1rem
      width: 9.7rem
      height: 4rem
      @media screen and ( max-width: 1024px )
        display: none

    .input
      outline: none
      color: black
      border: none
      font-size: 2rem
      padding: .5rem
      border-left: 2px solid black
  .user
    background-color: white
    padding: .5rem 1rem
    border-radius: 4rem
    display: flex
    align-items: center
    .userImg
      height: 3.75rem
      border-radius: 50%
    .userName
      margin: 0 1rem
      font-size: 1.5rem
.crumbsWrapper
  display: flex
  align-items: center
  font-size: 2.6rem
  .disabledCrumb
    color: #9b9b9b
    margin: 0
  .crumb
    color: #9b9b9b
    cursor: pointer
    &:last-of-type, &:only-of-type
      color: #222222 !important
      pointer-events: none
  .separator
    margin: 0 1rem
    &:last-of-type
      display: none

.switchLanguageToggleWrapper,.languageSelectIem
  height: 3.9rem
  display: flex
  border-radius: 5px
  border: solid 1px #ebebeb
  padding: 1.1rem .2rem
  .languageImage
    margin: 0 .8rem
    width: 3rem
  .languageLabel
    display: inline !important
    font-size: 1.4rem
  .arrowsWrapper
    display: flex !important
    flex-direction: column !important
    margin: 0 .8rem
    svg
      margin: -0.3rem
      font-size: 1.6rem
.languageSelectIem
  border: none
