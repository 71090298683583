.wrapper, .mobileWrapper
  width: 37rem
  min-height: 52rem
  border-radius: 5px
  box-shadow: 34px 55px 69px 0 rgba(31, 4, 48, 0.1)
  background-color: #ffffff
  margin-bottom: 1rem
  display: flex
  flex-direction: column
  padding: 2.4rem
  .separator
    margin-top: 2.4rem
  .brandAddressWrapper
    display: flex
    flex-direction: column
    padding-top: 1.8rem
    .brandAddressTitle
      font-size: 1.4rem
      font-weight: 900
      color: #1a1a1a
    .brandAddress
      font-size: 1.4rem
      color: #1a1a1a
  .brandTitle
    font-size: 1.8rem
    font-weight: 500
    color: #1a1a1a
  .brandWrapper
    display: flex
    flex-direction: row
    padding: 2.4rem 0
    .brand
      display: flex
      flex-direction: column
      align-items: center
      .phoneWrapper
        display: flex
        align-self: flex-start
        padding: 2rem 2rem
        .phone
          font-size: 1.6rem
          font-weight: 500
          color: #1a1a1a
      .brandInfo
        display: flex
        flex-direction: column
        padding: 0.2rem 2rem
        .title
          font-size: 1.6rem
          font-weight: 900
          color: #1a1a1a
        .address
          font-size: 14px
          color: #1a1a1a
      .brandAvatarWrapper
        z-index: 1
        width: 4.9rem
        height: 4.9rem
        border-radius: 3.2rem
        background-color: #f09440
        position: relative
        display: flex
        justify-content: center
        align-items: center
        font-size: 1.8rem
      .contactWrapper
        display: flex
        justify-content: center
        align-self: center
        align-items: center
        width: 3.2rem
        height: 8.4rem
        border-radius: 2rem
        background-color: rgba(65, 18, 108, 0.1)
        position: absolute
        padding: 1.8rem
        margin-top: 2.8rem

.mobileWrapper
  width: 100%
.country
  display: flex
  flex: 1
  flex-direction: column
  margin-top: 2.4rem
.countryTitle
  font-size: 1.2rem
  color: #595959
.countrySubTitle
  font-size: 1.6rem
  font-weight: 900
  color: #1a1a1a
.copyLink
  font-size: 1.6rem
  font-weight: 900
  color: #FFA351
  cursor: pointer
