.wrapper, .mobileWrapper
  display: flex
  flex-direction: column
  .customerInformationWrapper
    width: 37rem
    // height: 26rem
    border-radius: 5px
    box-shadow: 34px 55px 69px 0 rgba(31, 4, 48, 0.1)
    background-color: #ffffff
    display: flex
    flex-direction: column
    padding: 2.4rem
    .title
      font-size: 1.8rem
      font-weight: 500
      color: #1a1a1a
    .content
      display: flex
      flex-direction: row
      padding: 2.4rem 0
      // justify-content: space-around
      .contentWrapper
        display: flex
        flex-direction: column
        .infoWrapper
          display: flex
          flex-direction: column
          align-self: flex-start
          .name
            font-size: 1.6rem
            font-weight: 900
            color: #1a1a1a
          .address
            font-size: 1.4rem
            color: #1a1a1a
        .userInfoWrapper
          display: flex
          flex-direction: column
          // padding: 2rem 
          .openCustomerProfile
            color: #f09440
            text-decoration: underline
            font-size: 16px
            font-weight: 900
            cursor: pointer
          .phone,.phoneNumber
            font-size: 1.6rem
            font-weight: 500
            color: #1a1a1a
            padding: .35rem 0
          .phoneNumber
            padding-top: 1.2rem
            padding-bottom: 0rem
          .email
            font-size: 1.6rem
            font-weight: 500
            color: #1a1a1a
            padding: .7rem 0
        .contactWrapper
          padding: 1.7rem 0.8rem
          width: 3.2rem
          border-radius: 2rem
          background-color: rgba(65, 18, 108, 0.1)
          position: absolute
          display: flex
          flex-direction: column
          top: 10rem
          justify-content: space-evenly
          .phoneCall
            width: 1.6rem
            height: 1.7rem
            margin-top: 2rem
            margin-bottom: 1.3rem
          .mail
            width: 1.6rem
            height: 1.6rem
            margin-bottom: 1.3rem
          .order
            width: 1.6rem
            height: 1.6rem
            margin-top: 1.3rem
        .avatarWrapper
          z-index: 1
          position: relative
          width: 4.9rem
          height: 4.9rem
          border-radius: 3.2rem
          background-color: #f09440
          display: flex
          justify-content: center
          align-items: center
          font-size: 1.8rem
          // .avatarTitle
.mobileWrapper
  width: 100%
  .customerInformationWrapper
    width: 100%
    .content
      .contentWrapper
        .userInfoWrapper
          .openCustomerProfile
            font-size: 10px !important
            font-weight: 600 !important
        .contactWrapper
          top: inherit
          margin: 2rem 0
