
.search
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%
    margin: 0px 2rem
    height: 4rem
    border-radius: .5rem
    border-radius: 5px
    background-color: #f2f2f2
    padding: 1.5rem
    align-items: center
    .searchIcon
        width: 2rem
        cursor: pointer
        height: 2rem
    .input
        padding: 0 .25rem
        background-color: #f2f2f2
        height: 4rem
        outline: 0 !important
        width: 30rem
        border: 0 !important
        font-size: 1.4rem
        line-height: 0.88
        color: #000000
        &:disabled 
            color: rgba(0, 0, 0, 0.2) 
            background-color: #f2f2f2 !important
        .input::placeholder
            font-size: 1.4rem
            line-height: 0.88
            color: #9b9b9b
    
