
.inputWrapper
  display: flex
  width: 100%
  margin: 0
  justify-content: flex-start !important
  flex-direction: column
  position: relative
  top: 2rem
.label
  display: flex
  color: #2D2D2D
  flex: 1
  position: relative
  align-self: flex-start
  justify-content: space-between !important
  font-weight: 700
  font-size: 2.6rem
  span
    margin: 1rem .3rem !important
    font-size: 1.6rem
    font-weight: bold
    color: #2d2d2d
    display: flex
.formError
  font-weight: bold
  font-size: 1.6rem
  color: #FF0606

.input
  width: 100%
  outline: none
  border-radius: 5px
  font-size: 1.8rem
  font-weight: 500
  padding: 0 2rem
  color: #000000
  border: 1px solid #ffffff
  &.active
    border-color: #fdbb11
  &.valid
    border-color: #38c172
  &.invalid
    border-color: #db3030
.input::placeholder
  font-size: 1.6rem
  font-weight: normal
.input:read-only
  background-color: #e2e3e5

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0

input:disabled
  // border-radius: 5px !important
  // box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1) !important
  // border: solid 1px #e3e3e3 !important
  color: #5e5e5e !important
  background-color: #ffffff !important

.inputAndLoadingWrapper
  display: flex
  border-radius: 5px
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
  border: solid 1px #a6a6a6
  background-color: #ffffff
  width: 17.6rem
  height: 6.4rem
  // border: solid 1px #a6a6a6
  align-items: center
  // padding: 0 2rem
  .priceWrapper
    width: 7.5rem
    height: 100%
    border-radius: 0 5px 5px 0
    border: solid 1px #a6a6a6
    background-color: #f2f2f2
    display: flex
    justify-content: center
    align-items: center
    .price
      font-size: 1.8rem
      font-weight: 900
      text-align: right
      color: #1a1a1a
  .input
    border: none
    width: 8.9rem
    height: 5.5rem
    object-fit: contain
    border: solid 1px #ffffff
    background-color: #ffffff
    padding: 1rem
    flex: 1
  .incBtn
    width: 4.8rem
    height: 5.5rem
    object-fit: contain
    border-radius: 0 5px 5px 0
    background-color: #d9d9d9
    span
      font-size: 37px
    &:focus
      outline: 0
  .decBtn
    width: 4.8rem
    height: 5.5rem
    object-fit: contain
    border-radius: 5px 0 0 5px
    background-color: #d9d9d9
    span
      font-size: 3.9rems
    &:focus
      outline: 0
