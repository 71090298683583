.wrapper
  display: flex
  flex-direction: column
  .courierInfoWrapper
    width: 37rem
    border-radius: 5px
    box-shadow: 34px 55px 69px 0 rgba(31, 4, 48, 0.1)
    background-color: #ffffff
    display: flex
    flex-direction: column
    padding: 2.4rem    
    .title
      margin-top: 1rem
      font-size: 1.8rem
      font-weight: 500
      color: #1a1a1a
    .content
      display: flex
      flex-direction: row
      padding: 1.2rem 0
      width: 100%    
      .contentWrapper
        width: 100%
        .courierInfoContentWrapper
          display: flex                
          flex-direction: column          
          width: 100% 
          .courierInfoRow
            font-size: 1.5rem           
            display: flex
            flex-direction: row
            justify-content: flex-start
            margin-bottom: 1rem
            span 
              margin-left: 2.5rem
              margin-right: 2.5rem
        .deliveryInfoContentWrapper
          display: flex                
          flex-direction: row          
          width: 100% 
          flex-wrap: wrap
          justify-content: space-between
          .element
            width: 48%
            margin-top: 1.5rem
            .key 
              font-size: 1.2rem
            .value 
              font-size: 1.4rem
              font-weight: 900
            .valueLink
              font-size: 1.4rem
              font-weight: 900
              cursor: pointer
              color: #f09440
              text-decoration: underline

