.wrapper
  display: flex
  flex-direction: column
  .title
    font-size: 1.8rem
    font-weight: 500
    color: #1a1a1a
  .addressWrapper
    display: flex
    flex-direction: column
    .address, .customerLocationWrapper
      display: flex
      justify-content: space-between
      flex-direction: row
      .zone
        display: flex
        flex-direction: column
        margin-top: 2.4rem
        .zoneTitle
          font-size: 1.2rem
          color: #595959
        .zoneSubTitle
          font-size: 1.6rem
          font-weight: 900
          color: #1a1a1a
      .country
        display: flex
        flex: 1
        flex-direction: column
        margin-top: 2.4rem
        .countryTitle
          font-size: 1.2rem
          color: #595959
        .countrySubTitle
          font-size: 1.6rem
          font-weight: 900
          color: #1a1a1a
        .copyLink  
          font-size: 1.6rem
          font-weight: 900
          color: #FFA351
          cursor: pointer
          
    .customerLocationWrapper
      display: flex !important
      flex-direction: column !important