@import ../../../../../sass/colors

.dropDownPart
  display: flex
  flex-direction: column
  margin: 0
  span
    font-size: 1.6rem
    color: #222222
    position: relative
    margin: 0
  .switch
    font-size: 1.6rem !important
    margin: 0 !important
    width: 100%
    border-radius: 5px !important
    border: solid 1px #ebebeb !important
    outline: none !important
    background-color: white !important
    text-align: left !important
    padding: 0 !important
    font-weight: 500 !important
    color: #222222 !important
    box-shadow: none !important
    div
      display: flex !important
      align-items: center !important
      justify-content: space-between !important

  .switch:disabled
    background-color: #F3F3F3 !important
    cursor: default
.menuWrapper::-webkit-scrollbar-button
  height: 90px
.menuWrapper
  max-height: 30rem
  overflow: auto
  min-width: 100% !important
  // padding: 2rem 0 !important
  border-radius: .5rem
.dropdown-menu
  padding: 0px 0px !important

  ::focus
    outline: none
  
  button
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15)
    border: 0 none
    padding: 1rem 1.6rem !important
    word-break: break-all !important
    white-space: unset !important
    outline: none
    background-color: #ffffff

    &:hover
      outline: none
      border: none
      background-color: #fff
    .item
      padding: 2rem
      font-size: 18px
      outline: 0 !important
.dropdown-item , .item
  &:last-child
    position: sticky
    bottom: 0
    padding: 0
    display: flex
    flex-direction: column

button.dropdown_item:active ,
button.dropdown_item:focus
  background-color: rgba(0,0,0,0.05) !important
  border-color: none !important
  outline: none !important
