.container
  display: flex
  flex-direction: column
  width: 100%
  .dropDownWrapper
    padding: 3.2rem 8.6rem .8rem 4rem
    width: 100%
    justify-content: flex-end
    align-items: center
    display: flex
    flex-direction: row
    .title
      margin: 0 .4rem
      font-size: 1.7rem
      font-weight: 500
      color: #2d2d2d
  .main
    display: flex
    flex-direction: row
    width: 100%
    padding: 0 8.7rem
    height: 71.4rem
    .wrapperMain
      height: 71.4rem
      overflow: auto
      //flex: 1
      border-radius: 10px
      box-shadow: 3px 2px 24px 0 rgba(0, 0, 0, 0.1)
      background-color: #ffffff
      //position: relative
      //z-index: 1
    //.wrapperMain::-webkit-scrollbar
    //  width: .3rem
    //.wrapperMain::-webkit-scrollbar-thumb
    //  background-color: #d9d9d9
    //  border-radius: 2.5px
.selectItem
  width: 24.8rem
  height: 5.2rem
  border-radius: 5px
  padding: 1.5rem
  display: flex
  flex-direction: row
  justify-content: space-between
  background-color: #ffffff
  .icon
    width: 1.6rem
    height: 1.6rem
  .title
    font-size: 1.6rem
    font-weight: 500
    line-height: 0.88
    color: #222222
.itemSelected
  padding: 1rem

.disconnectedStoresWrapper , .connectedStoresWrapper , .closedStoresWrapper
  height: calc( 60vh / 3 )
  overflow-y: scroll
  overflow-x: hidden
