.wrapper
  width: 100%
  display: flex
  padding: 1.6rem
  justify-content: space-between
  align-items: center
  cursor: pointer
  border-bottom: 1px solid #e6e6e6
  .mainWrapper
    display: flex
    align-items: center
    .brandImageWrapper
      .brandImage
        border-radius: 2rem
        width: 4rem
        height: 4rem
    .brandInfoWrapper
      padding: 0 0.8rem
      .callCenter
        display: flex
        flex-direction: row
        align-items: center
        .callCenterIcon
          width: 1.4rem
          height: 1.4rem
        .callCenterTitle
          margin: 0 .4rem
          font-size: 1.4rem
          font-weight: 500
          color: #595959
      .brandName
        font-size: 1.8rem
        font-weight: 900
        color: #1a1a1a

      .brandInfo
        display: flex
        flex-direction: row
        height: 1.9rem
        .brandTitle
          font-size: 1.4rem
          font-weight: 500
          color: #595959
          margin: 0 0.3rem
        .disconnectedWrapper
          display: flex
          flex-direction: row
          align-items: center
          margin: 0 .3rem
          .disconnectedBranchTitle
            font-size: 1.4rem
            font-weight: 500
            margin: 0 .2rem
            color: #595959
          .disconnectedBranchNumbers
            font-size: 1.4rem
            font-weight: 900
            color: #1a1a1a
            margin: 0 .2rem
  .errorIconWrapper
    width: 3.2rem
    height: 3.2rem
    border-radius: 1.6rem
    background-color: rgba(219, 48, 48, 0.2)
    .brandImage
      margin: 0.8rem

  &:hover
    background-color: rgba(65, 18, 108 , 0.1)
    border-left: 3px solid #41126c
.disconnected
  border-left: 3px solid #db3030
.connected
  border-left: 3px solid #38c172
.closed
  border-left: 3px solid #ffffff
.manualDispatched
  border-left: 3px solid #f7cc49
