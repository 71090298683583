.main
  display: flex
  flex-direction: column
  .headerWrapper
    width: 100%
    height: 3.2rem
    background-color: #f2f2f2
    padding: .8rem 2.4rem
    margin: 0.8rem 0
    .headerTitle
      font-size: 1.2rem
      font-weight: 900
      color: #a6a6a6
  .wrapper
    width: 100%
    padding: 1.6rem 2.4rem
    height: 7.2rem
    box-shadow: 0 .6rem 1.2rem 0 rgba(31, 4, 48, 0.1)
    background-color: #ffffff
    margin: 1.6rem 0
    border-left: .3rem solid #db3030
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    align-self: center
    .statusWrapper
      display: flex
      flex-direction: row
      .icon
        display: none
        width: 1.4rem
        height: 1.4rem
        transition: all 2s
      .btnWrapper
        display: none
        transition: all 2s
        width: 3.2rem
        height: 3.2rem
        border-radius: 3.4rem
        box-shadow: 0 1.2rem 2.4rem 0 rgba(31, 4, 48, 0.1)
        background-color: rgba(65, 18, 108, 0.2)
        margin: 0 .8rem
      .statusLabelWrapper
        display: flex
        flex-direction: column
        .version
          display: flex !important
          justify-content: flex-end !important
          font-size: 1.2rem
          font-weight: 500
          color: #1a1a1a
        .labelWrapper
          display: flex
          flex-direction: row
          align-items: center
          .statusLabel
            font-size: 1.4rem
            font-weight: 900
            color: #db3030
          .dot
            margin: 0 .2rem
            width: .8rem
            height: .8rem
            border-radius: .4rem
            background-color: #db3030
    .titleWrapper
      display: flex
      flex-direction: column
      .title
        font-size: 1.6rem
        font-weight: 900
        color: #1a1a1a
      .disconnectedWrapper
        display: flex
        flex-direction: row
        .disconnectedLabel
          font-size: 1.2rem
          color: #595959
        .time
          font-size: 1.2rem
          font-weight: 900
          color: #595959
          margin: 0 .1rem
    &:hover
      .icon
        display: flex
        opacity: 1
        width: 1.4rem
        height: 1.4rem
        transition: all 2s
      .btnWrapper
        display: flex
        opacity: 1
        transition: all 2s
        width: 3.2rem
        height: 3.2rem
        border-radius: 3.4rem
        box-shadow: 0 1.2rem 2.4rem 0 rgba(31, 4, 48, 0.1)
        background-color: rgba(65, 18, 108, 0.2)
        margin: 0 .8rem
