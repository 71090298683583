@import '../../../../sass/colors'

.NavigationItem
  margin: 0 0 2rem
  display: flex
  justify-content: center
  align-items: center
  width: 100%

  img
    width: 2.4rem
    height: 2.4rem

  a
    transition: .2s all
    color: white
    text-decoration: none
    border-radius: 5px
    display: flex
    justify-content: flex-start
    align-items: center
    width: 100%
    font-weight: 500
    cursor: pointer

    &:hover
      background-color: $koinz-primary-color
      font-weight: 700
      color: white
      text-decoration: none
      justify-content: flex-start

    &:active,
    &.active
      display: flex

      // justify-content: center
      background-color: $koinz-primary-color
      font-weight: 800
      color: white
      text-decoration: none

    &:hover,
    &:active,
    &.active

      p
        background: white
        color: #FDBB11
