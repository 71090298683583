button
  border: none
.genericButtonDefaults
  display: flex
  justify-content: center
  align-items: center
  &:hover
    background-color: #F2A158
    color: #ffffff
  p
    margin-top: 1.1rem
    align-self: center !important