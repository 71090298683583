.App
  background-color: white
  min-height: 100vh
  font-family: Avenir, 'Avenir LT Std','Cairo','Roboto', sans-serif
  font-weight: 400
  width: 100%
  .loaderWrapper
    width: 100% !important
    height: 100vh !important
    display: flex !important
    justify-content: center !important
    align-items: center !important
.separator
  width: 100%
  height: 7rem