
.rtl
  .menu
    text-align: right
  .footer
    direction: ltr

.navBar
  width: 21.4rem
  height: 100vh
  overflow: auto
  background: #222
  z-index: 1
  display: flex
  position: fixed
  top: 0
  flex-direction: column
  justify-content: flex-start
  align-items: center
  box-shadow: 0 12px 24px rgba(0, 23, 59, 0.1)
  transition: all .2s
  padding: 0 1.5rem
  .footer
    color: #6b6961
    margin: 1.5rem 0
    justify-content: center !important
    font-size: 1.2rem
  .separator
    width: 100%
    height: 1px
    margin: 0 0 2rem
    background: #363636
  .items
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    width: 100%
    margin: 0
    background: #222
    .title
      color: #9b9b9b
      font-size: 1.4rem
      font-weight: 500
      align-self: flex-start
      margin: 1rem 3rem 2rem
      text-transform: uppercase
    .btn
      border: none
      margin: 0 1rem
      background-color: white
      color: black
      width: 55%
      font-size: 2.5rem
      outline: none
      display: flex
      align-items: center
      justify-content: space-between
      height: 5rem
    .btn:hover
      color: black
      border-radius: 40px

  .logo
    width: 100%
    display: flex
    margin-bottom: 5rem
    justify-content: center
    align-items: center
    img
      width: 11rem

.userDD
  background: #222 !important
  border: 0 !important
  outline: 0 !important
  margin: .5rem 0 !important
  width: 100% !important
  display: flex !important
  align-items: center !important
  justify-content: center !important
  box-shadow: none !important
  *
    margin: 0
  .dd
    margin: 0 1.5rem
    width: 7px
    height: 15px
  .brandImg
    width: 3.7rem
    height: 3.7rem
    border-radius: 50%
  .userData
    margin: 0 1rem
    display: flex
    flex-direction: column
    .userName
      color: #6b6961
      font-weight: 500
      font-size: 1.2rem
    .brandName
      color: white
      font-size: 1.4rem
      font-weight: 500

.menu
  width: 100%
  margin: 0 -5px
  padding: 0 !important
  top: 0 !important
  .item
    padding: 1rem 2rem
    font-size: 1.6rem
    font-weight: 500
    outline: 0 !important
    background: white
  .item:hover
    background: rgba(254,190,25,.1)

.showS
  display: inline !important
  transition: all .2s

.hideS
  transition: all .2s
  display: none !important
