.wrapper
  width: 100%
  display: flex
  flex-direction: row
  margin-top: 2.6rem
  .imgWrapper
    margin: 0 1.6rem
    width: 4rem
    display: flex
    justify-content: center
    align-items: center
    height: 4rem
    border-radius: 1rem
    background-color: rgba(65, 18, 108, 0.1)
    .icon
      width: 1.6rem
      height: 1.6rem
  .contentWrapper
    display: flex
    flex-direction: column
    .title
      font-size: 1.4rem
      font-weight: 500
      color: #595959
    .cashierIdWrapper
      display: flex
      flex-direction: row
      .subTitle
        font-size: 1.4rem
        font-weight: 900
        color: #1a1a1a
      .cashierId
        margin: 0 3px
        font-size: 1.2rem
        font-weight: 500
        color: #a6a6a6
