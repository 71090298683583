.wrapper
  padding: 2.4rem 3rem
  position: sticky
  z-index: 1
  background: #fff
  top: 0
  width: 37rem
  .headerWrapper
    display: flex
    justify-content: space-between
    align-items: center
    .brandsCounter
      font-size: 2.2rem
      font-weight: 500
      color: #1a1a1a
  .searchWrapper
    margin: 1.6rem 0
    display: flex
    flex-direction: row
    .search
      display: flex
      flex-direction: row
      justify-content: space-between
      width: 100%
      height: 4.8rem
      border-radius: .5rem
        // box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.15)
      border: solid 1px #ebebeb
      background-color: #ffffff
      padding: 1.5rem
      align-items: center
      .searchIcon
        width: 2rem
        height: 2rem
      .input
        padding: 0 .25rem
        height: 4rem
        outline: 0 !important
        width: 30rem
        border: 0 !important
        font-size: 1.6rem
        line-height: 0.88
        color: #000000
        .input::placeholder
          font-size: 1.6rem
          line-height: 0.88
          color: #9b9b9b
