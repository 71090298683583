.wrapper
  height: 24.7rem
  background-color: #f09440
  display: flex
  justify-content: flex-start
  align-items: center
  flex: 1
  align-content: space-between
  position: relative
  flex-direction: column
  .orderWrapper
    width: 100%
    display: flex
    justify-content: space-between
    padding: 2.4rem 8.7rem 0
    .btnWrapper,.mobileBtnWrapper
      width: 6.4rem
      height: 4.8rem
      border-radius: 5px
      background-color: rgba(255,255,255,0.4)
      font-size: 1.6rem
      font-weight: 500
      color: #41126c
      display: flex
      flex-direction: row
      justify-content: space-evenly
      outline: none
    .mobileBtnWrapper
      height: 6.4rem
      border-radius: 4px !important  
      img 
        width: 12px
        height: 12px
    
    .icon
      width: 1.7rem
      height: 1rem
    .orderTypeWrapper, .orderTypeWrapperPickup, .orderTypeWrapperDriveThru
      height: 32px !important
      padding: 0 12px
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      border-radius: 4px
      background-color: #ffffff
      .orderTypeIcon
        width: 16px
        height: 16px
      .orderTypeTitle, .orderTypeTitlePickup, .orderTypeTitleDriveThru
        font-size: 14px
        font-weight: 900
        color: #f09440
        padding: 0 8px
      .orderTypeTitlePickup
        font-size: 14px
        font-weight: 900
        color: #41126c
        padding: 0 8px
      .orderTypeTitleDriveThru
        font-size: 14px
        font-weight: 400
        color: #0FCB5D
        padding: 0 8px
    .orderTypeWrapperDriveThru
      border: 1px solid #0FCB5D
      background-color: #E6FFEE
    .title, .transactionTitle
      font-size: 2.4rem
      font-weight: 900
      text-align: center
      color: #ffffff
      padding: 0 4.1rem
      margin-top: 1rem
    .transactionTitle
      font-size: 1.6rem
    .arrivalTitle
      font-size: 1.8rem
      font-weight: 500
      text-align: center
      color: #ffffff
      padding: 0 4.1rem
    .helper
      display: flex
      flex-direction: column
      .onManualWrapper
        width: 33.8rem
        height: 5.6rem
        padding: .8rem .8rem .8rem 1.6rem
        margin-top: 1.1rem
        border-radius: .4rem
        background-color: #ffffff
        display: flex
        justify-content: space-between
        flex-direction: row
        .branchDisconnected
          display: flex
          flex-direction: row
          justify-content: center
          align-items: center
          width: 90%
          .branchDisconnectedIcon
            width: 1.3rem
            height: 1.3rem
          .branchDisconnectedTitle
            font-size: 1.4rem
            font-weight: bold
            line-height: 1.29
            text-align: center
            color: #38c172
        .onManualTitle
          font-size: 1.4rem
          font-weight: 500
          color: #db3030
        .orderDispatchingBtn
          font-size: 1.4rem
          font-weight: bold
          line-height: 1.29
          text-align: center
          color: #ffffff
          width: 15.2rem
          height: 4rem
          border-radius: .4rem
          background-color: #db3030
          output: none
      .goToCashier
        display: flex
        justify-content: center
        align-items: center
        padding: 0 3.2rem
        height: 4.8rem
        border-radius: 4px
        color: #41126c
        font-size: 1.6rem
        font-weight: bold
        background-color: #ffffff
        &:disabled
          opacity: 0.8
          cursor: not-allowed
  .orderDetailsWrapper
    position: relative
  .detailsWrapper, .mobileWrapper
    position: absolute
    top: 18.5rem
    display: flex
    justify-content: space-around
    flex-direction: row !important
    .orderTrackingWrapper
      display: flex
      flex-direction: column
      min-height: 100%
      .typeWrapper
        display: flex
        flex-direction: column
        width: 27rem
        display: flex
        border-radius: 5px
        box-shadow: 34px 55px 69px 0 rgba(31, 4, 48, 0.1)
        background-color: #ffffff
        padding: 1.2rem 1.6rem !important

  .mobileWrapper
    flex-direction: column !important
    width: 100%
    padding: 24px
    .orderTrackingWrapper
      width: 100%
      .typeWrapper
        width: 100%
        margin-bottom: 5rem
  