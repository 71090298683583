@import ../../../sass/colors

.NavigationItems
  list-style: none
  display: flex
  flex-flow: column
  justify-content: center
  align-items: center
  margin: 0
  margin-top: 14rem

  .bar
    display: flex
    padding: .8rem 1.5rem
    justify-content: flex-start
    align-items: center !important
    font-size: 1.6rem
    color: inherit

  .icon
    display: flex
    align-items: center
    justify-content: center
    margin: 0 0.5rem
    width: 2.4rem !important
    height: 2.4rem !important

  span
    margin: 0 .8rem
    padding: 0

  .issuesCount
    margin: 0
    font-size: 1rem
    font-weight: 900
    line-height: 1.8
    letter-spacing: .6px
    color: #fff
    background: $koinz-primary-color
    padding: 0 1.4rem
    border-radius: 8.5px

.hideNavItem
  display: none !important

.showNavItem
  display: flex !important
