.itemWrapper, .mobileItemWrapper
  width: 100%
  padding: 2.5rem 0
  border-bottom: 2px solid rgba(31, 4, 48, 0.1)
  &:last-child
    border-bottom: none
  .itemHeaders
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%
    .itemNumber
      flex: 1
      font-size: 1.8rem
      font-weight: 900

      color: #1a1a1a
    .content
      flex: 4
      .header
        font-size: 1.6rem
        font-weight: bold
        color: #1a1a1a

    .price
      flex: 2
      span
        font-size: 1.4rem
        font-weight: 900
        color: #1a1a1a
        margin-left: 2.5rem
        margin-top: 0.3rem

  .mod
    display: flex
    flex-direction: row
    margin-bottom: 1.6rem
    .whiteSpace
      flex: 1
    .modNames
      flex: 4
      margin: 0.2rem -1.4rem
      .itemSpan
        padding-right: 2rem
        font-size: 1.6rem
        color: rgba(89,89,89 , 0.7)

    .modPrices
      flex: 1
      .itemPriceSpan
        font-size: 1.4rem
        font-weight: 900

        color: #1a1a1a

.extras
  font-size: 1.4rem
  font-weight: bold

  color: #1a1a1a
  margin-top: 1rem
.categoryWrapper
  border-radius: 4px
  padding: 0 0.8rem
  display: inline-flex
  flex-direction: row
  position: relative
  left: 6rem
  background-color: rgba(65,18,108,0.2)
  .category
    font-size: 1.4rem
    font-weight: bold
    color: #41126c
  .separator
    font-size: 1.4rem
    font-weight: bold
    color: #41126c
    margin: 0 .5rem
.extrasSpace
  height: 3.5rem
  width: 1rem

.mobileItemWrapper
  .itemHeaders
    .itemNumber
      flex: 0.45

.commentContainer
  margin-top: 24px

.commentTitle
  font-size: 1.2rem
  color: #595959

.commentSubTitle
  font-size: 1.6rem
  font-weight: 900
  color: #1a1a1a
