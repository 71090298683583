
    .content
      display: flex
      flex-direction: row
      padding: 1.4rem 2.8rem
      border-bottom: 1px solid #e6e6e6
      .phoneCall
        width: 4.8rem
        height: 4.8rem
        border-radius: 50%
        margin-top: 1.1rem
        background-color: rgba(65, 18, 108, 0.35)
        display: flex
        justify-content: center
        align-self: center
        img
          width: 2.4rem
          height: 2.4rem
          margin: auto

      .infoWrapper
        display: flex
        width: 100%
        flex-direction: column
        padding: 1rem

        .helpRequestWrapper
          display: flex
          flex-direction: row
          justify-content: space-between
          .helpRequestTitle
            font-size: 1.2rem
            font-weight: 900
            color: #41126c
            text-transform: uppercase
          .close
            cursor: pointer
        .brandWrapper
          margin: .8rem 0 0 0
          display: flex
          flex-direction: column
          .title
            font-size: 1.4rem
            font-weight: 900
            color: #1a1a1a
          .secondTitle
            font-size: 1.4rem
            font-weight: 500
            color: #1a1a1a
        .phoneNumber
          font-size: 1.4rem
          font-weight: 900
          color: #41126c
.contentWrapper
  height: 40rem
  overflow: scroll
.header
  font-size: 2.2rem
  font-weight: 500
  color: #2d2d2d
  padding: 0 3rem
  padding-top: 3.2rem
  padding-bottom: 2.4rem
.closeModal
  border-radius: 1rem
  background-color: #f2f2f2
  width: 100%
  padding: 1.6rem 2.4rem
.btn
  float: right
  background-color: transparent
  border-radius: 0.5rem
  border: solid 1px #ee8728
  padding: 0.4rem 5.4rem
  font-size: 1.4rem
  font-weight: bold
  line-height: 1.29
  letter-spacing: 0.1px
  text-align: center
  color: #ee8728
  
  