.main
    display: flex
    justify-content: center
    align-items: center
    .wrapper
        display: flex
        flex-direction: column
        width: 50.8rem
        border-radius: 1rem
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.13)
        background-color: #ffffff
        outline: none
        .closeWrapper
            display: flex
            flex-direction: row
            justify-content: space-between
            padding: 2rem
            img
                &:hover
                    cursor: pointer
        .title
            font-size: 2rem
            font-weight: 500
            color: #1a1a1a
        .footer
            width: 100%
            height: 8rem
            border-radius: 1rem
            background-color: #f2f2f2
            display: flex
            flex-direction: row
            justify-content: flex-end
            padding: 0 2.4rem
            align-items: center
        .editReasonBtn, .cancelBtn, .disabled,.deleteBtn
            display: flex
            align-items: center
            justify-content: center
            width: 15.7rem
            height: 4.8rem
            border-radius: 5px
            background-color: #ee8728
            font-size: 1.4rem
            font-weight: bold
            line-height: 1.29
            letter-spacing: 0.1px
            text-align: center
            color: #ffffff
            outline: none
        .disabled
            cursor: not-allowed
            background-color: #a6a6a6
            paddigng: 3px
        .cancelBtn
            width: 14.4rem
            margin: 0 1rem
            color: #ee8728
            background-color: #f2f2f2
            border-radius: 4px
            border: solid 1px #ee8728
        .deleteBtn
            margin: 0 1rem
            color: #fff
            background-color: #ee5253
            border-radius: 4px
        .contentWrapper
            display: flex
            flex-direction: column
            padding: 0 3.2rem 0
        .title
            font-size: 2.2rem
            font-weight: 500
            color: #2d2d2d
        .dropdownWrapper
            display: flex
            flex-direction: column
            margin-top: 2.6rem
            margin-bottom: 3.2rem
            .label
                margin-bottom: .5rem
                font-size: 16px
                font-weight: bold
                color: #2d2d2d
.selectItem
    width: 100%
    padding: 1rem 1.6rem
    height: 5.5rem
    display: flex
    flex-direction: row
    justify-content: space-between
    border-radius: 5px
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
    border: solid 1px #a6a6a6
    background-color: #ffffff
.dropdownTitle, .placeholder
    font-size: 1.6rem
    color: #1a1a1a
.placeholder
    color: #a6a6a6

.itemSelected
    display: flex
    flex-direction: row
    height: 5.5rem
    align-items: center
    width: 100%
.acceptanceTime
    height: 48px
    flex-grow: 0
    display: flex
    flex-direction: row
    align-items: center
    gap: 8px
    padding: 12px
    border-radius: 4px
    background-color: #eae8f3
    p
        color: #503e9d
        padding-top: 10px

.alertBox
    align-self: stretch
    flex-grow: 0
    display: flex
    flex-direction: column
    align-items: stretch
    gap: 8px
    padding: 12px
    font-size: 15px
    border-radius: 4px
    border: solid 1px #ee5253
    background-color: #fdeaea
    color: #ee5253
    .alertContent
        display: flex
        flex-direction: row
        align-items: center
        .alertImageContainer
            width: 25px
            height: 43px
            margin-right: 10px

    ul
        padding-left: 45px
        li p
            flex-wrap: wrap
            font-size: 14px

.cancelDetails
    height: 48px
    flex-grow: 0
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    gap: 8px
    border-radius: 4px
    font-size: 14px
    .iconRoundWrapper
        width: 40px
        height: 40px
        padding: 12px
        border-radius: 80px
        background-color: #f2f2f7
        img
            line-height: 40px
            text-align: center
    p
        color: #65656c
        padding-top: 10px
        span
            font-weight: bold
.loaderWrapper
    display: flex
    align-items: center
    justify-content: center
