.wrapper
  width: 43.3rem
  border-radius: 10px
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.13)
  background-color: #ffffff
  padding-top: 3.2rem
  outline: none
  .title
    padding-left: 4rem

    font-size: 2.2rem
    font-weight: 500
    color: #2d2d2d
  .btnWrapper
    width: 43.3rem
    height: 8rem
    border-radius: 10px
    background-color: #f2f2f2
    display: flex
    flex-direction: row
    align-items: center
    padding: 1.6rem 2.4rem 1.6rem 10.4rem
    justify-content: space-around
    .edit
      width: 14.4rem
      height: 4.8rem
      border-radius: 5px
      background-color: #f09440
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      text-align: center
      color: #ffffff
    .cancel
      width: 14.4rem
      height: 4.8rem
      border-radius: 5px
      border: solid 1px #ee8728
      background-color: #f2f2f2
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      text-align: center
      color: #ee8728
  .separator
    margin-top: 6rem
  .inputWrapper
    padding-left: 4rem
